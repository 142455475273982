/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Source+Code+Pro&display=swap');

.App {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #f4f4f9;
  min-height: 100vh;
}

h1 {
  color: #333;
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 500;
}

h2 {
  color: #555;
  font-size: 1.5em;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 500;
}

.timer {
  font-size: 1.5em;
  color: #ff5722;
  margin-bottom: 20px;
  font-family: 'Source Code Pro', monospace;
}

.workout-timer {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 20px;
  font-family: 'Source Code Pro', monospace;
}

button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  background-color: #4a90e2;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #357ab8;
}

table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 80%;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

th {
  background-color: #4a90e2;
  color: #fff;
}

td {
  background-color: #fff;
}

input[type="checkbox"] {
  transform: scale(1.2);
  margin: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  table {
    width: 100%;
  }

  th, td {
    padding: 8px;
  }
}